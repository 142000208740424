import React from 'react';
import Link from 'next/link';

import styles from './errorPage.module.scss';
import Layout from '../components/layout/layout';
import useLanguage from '../components/_hooks/useLanguage';

const Custom404 = () => {
	const { t } = useLanguage();

	return (
		<Layout>
			<div className={styles.errorPage}>
				<h1>{t('labelPageNotFoundTitle')}</h1>
				<p>
					<Link href="/"><a>{t('navHome')}</a></Link>
					<Link href="/drazbe"><a>{t('navListView')}</a></Link>
					<Link href="/mojedrazbe-pro"><a>{t('navAbout')}</a></Link>
					<Link href="/cenik"><a>{t('navPricelist')}</a></Link>
				</p>
			</div>
		</Layout>
	);
};

export default Custom404;
